<template>
  <base-dialog :title="titleLabel" customActivator>
    <template #custom-activator="{ events: { handleOpenDialog } }">
      <span
        class="red--text text--light-3 font-weight-medium"
        @click="handleOpenDialog"
      >
        {{ $t("forgotYourPassword") }}
      </span>
    </template>
    <template #content>
      <section class="my-4">
        <p class="my-5">
          {{ $t("forgotYourPasswordModalText1") }}
        </p>
        <p class="my-5">
          {{ $t("forgotYourPasswordModalText2") }}
        </p>
        <ForgotYourPasswordForm />
      </section>
    </template>
  </base-dialog>
</template>

<script>
import { i18n } from "@/plugins/i18n";

export default {
  name: "ForgotYourPassword",
  computed: {
    titleLabel: () => i18n.t("forgotYourPassword"),
  },
  components: {
    ForgotYourPasswordForm: () => import("./ForgotYourPasswordForm.vue"),
  },
};
</script>
